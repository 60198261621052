import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { remember } from '../store/actions/auth';
import { getAllProducts } from '../store/actions/products';
import {
  calculateAge,
  customRegexValidation,
  getCities,
  getCountries,
  getDistricts,
  validateEmail,
  validatePassword,
} from '../utils/utils';

const useForm = (
  callback,
  formState,
  datepickername,
  resetForm = false,
  isConvalida = false,
  isMinimal,
  userInfo,
  setScroll = () => {},
  profile,
  isValid,
) => {
  const [formData, setFormData] = useState(formState);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [control, setControl] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [startDate, setStartDate] = useState({ date: null, name: null });

  const [selectedFile, setSelectedFile] = useState(null);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const [districts, setDistricts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcode, setZipcode] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  const [variantLabel, setVariantLabel] = useState(null);

  const [variantValue, setVariantValue] = useState(null);
  useEffect(() => {
    if (userInfo) {
      setIsUpdated(true);
    }
  }, [userInfo]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isUpdated) return;
    setFormData(formState);
  }, [isMinimal, userInfo]);

  const checkValidity = (value, rules, target) => {
    let isValid = true;

    if (rules.required) {
      isValid = value !== '' && isValid;
      if (target?.type === 'checkbox') {
        if (target?.checked === true) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
      if (target?.type === 'radio') {
        isValid = true;
        if (rules.requiredValue) {
          if (target?.value === rules.requiredValue) {
            isValid = true;
          } else {
            isValid = false;
          }
        }
      }
      if (target?.type === 'file') {
        if (target?.value) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
    }
    if (rules.isEmail) {
      isValid = validateEmail(value) && isValid;
    }
    if (rules.isString) {
      isValid = /^[A-Za-z]+$/.test(value) && isValid;
    }
    if (rules.isPassword) {
      isValid = validatePassword(value) && isValid;
    }
    if (rules.isAdult) {
      isValid = calculateAge(new Date(value)) && isValid;
    }
    if (rules.isConfirmPassword) {
      isValid = value === passwordValue && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.isCustomCode) {
      isValid = customRegexValidation(rules.regex, value) && isValid;
    }

    return isValid;
  };
  const selectChange = value => {
    const updateFormData = {
      ...formData,
    };
    const updateFormDataElement = {
      ...updateFormData['store'],
    };

    updateFormDataElement.value = value;
    updateFormDataElement.valid = value ? true : false;
    updateFormDataElement.idPdv = value?.id;
    updateFormDataElement.touched = true;
    updateFormData['store'] = updateFormDataElement;

    let formIsValid = true;
    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid && formIsValid;
    }

    setControl(true);
    setFormIsValid(formIsValid);
    setFormData(updateFormData);
  };
  const inputChangedHandler = event => {
    const updateFormData = {
      ...formData,
    };
    setVariantLabel(event.target.name);

    setVariantValue(event.target.value);
    /*   if (event.target.name === 'store') {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const selectedId = selectedOption.getAttribute('data-id');
      updateFormData['store'].idPdv = selectedId;
    } */
    if (event.target.name === 'address1') {
      if (event.target.value === 'San Marino') {
        setDistricts([]);
        setDistricts([{ id: 1, country: 'SM', name: 'San Marino', value: 'San Marino' }]);
      }
    }
    if (event.target.name === 'country') {
      setDistricts([]);
      if (event.target.value === 'San Marino') {
        setDistricts([{ id: 1, country: 'SM', name: 'San Marino', value: 'San Marino' }]);
      }
      getDistricts(event.target.value).then(data => {
        setDistricts(data);
      });
    }
    if (event.target.name === 'district' || formData?.district?.value) {
      setCities([]);
      if (event.target.value === 'San Marino' || formData?.district?.value === 'San Marino') {
        setCities([{ id: 1, name: 'San Marino', value: 'San Marino' }]);
      } else {
        getCities(formData?.district?.value || event.target.value).then(data => {
          setCities(data);
        });
      }
    }
    if (event.target.name === 'city') {
      let zipcode = cities.filter(city => city.name === event.target.value)[0].zipcode;
      setZipcode(zipcode);
      updateFormData['zipcode'].valid = true;
      updateFormData['zipcode'].value = cities.filter(
        city => city.name === event.target.value,
      )[0].zipcode;
    }

    const updateFormDataElement = {
      ...updateFormData[event.target.name],
    };

    updateFormDataElement.value =
      event.target.type === 'checkbox'
        ? event.target.checked
          ? '1'
          : '0'
        : updateFormDataElement.validation.isString
        ? event.target.value.replace(/[^a-zA-Z]/g, '')
        : updateFormDataElement.validation.isNumber
        ? event.target.value.replace(/[^0-9]/g, '')
        : event.target.name === 'amount'
        ? event.target.value //.replace(/\D/g, '')
        : event.target.value || '';

    //event.target.type === 'radio' ? (event.target.checked ? '1' : '0') : event.target.value;
    if (event.target.name === 'recordami') {
      dispatch(remember(+updateFormDataElement.value));
    }

    updateFormDataElement.valid = checkValidity(
      updateFormDataElement.value,
      updateFormData[event.target.name].validation,
      event.target,
    );

    updateFormDataElement.touched = true;
    updateFormData[event.target.name] = updateFormDataElement;

    let formIsValid = true;
    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid && formIsValid;
    }

    if (updateFormDataElement.validation.toMatch) {
      setPasswordValue(updateFormDataElement.value);
    }
    if (event.target.name === 'area') {
      dispatch(getAllProducts(1, 18, updateFormData.area.value));
    }

    setControl(true);
    setFormIsValid(formIsValid);
    setFormData(updateFormData);
  };

  const resetFormHandler = () => {
    const updateFormData = {
      ...formData,
    };

    for (let i in updateFormData) {
      if (updateFormData[i].elementType === 'inputfile') {
        if (updateFormData[i].valid === true) {
          updateFormData[i].valid = true;
        }
      } else if (updateFormData[i].isReset === true) {
        updateFormData[i].value = '';
        updateFormData[i].valid = true;
      } else {
        updateFormData[i].value = '';
        updateFormData[i].valid = false;
      }
    }
    setFormData(updateFormData);
  };
  const resetInputHandler = val => {
    const updateFormData = {
      ...formData,
    };
    if (!updateFormData[val]) return;
    updateFormData[val].value = '';
    updateFormData[val].valid = isValid ? true : false;
    setFormData(updateFormData);
  };

  const formSubmitHandler = (event, type = null) => {
    event && event.preventDefault();

    setFirstSubmit(true);
    if (control) {
      setShowError(true);
    }
    let formDataObj = {};
    for (let formEl in formData) {
      if (formData[formEl].valuetosend) {
        formDataObj[formEl] = formData[formEl].valuetosend;
      } else {
        formDataObj[formEl] = formData[formEl].value;
      }
    }
    let checkoutValid = true;

    for (let key in formData) {
      checkoutValid = formData[key].valid && checkoutValid;
    }

    if (isConvalida && formData['prize'].value === '0') {
      callback(formDataObj);
    }

    if (formIsValid) {
      callback(formDataObj, type);
    } else if (checkoutValid) {
      callback(formDataObj);
    } else {
      setScroll(prev => !prev);
    }
  };

  const datePickerHandler = (date, name) => {
    setStartDate({ date, name });
  };
  const handleKeyDown = e => {
    if (e.key === 'Delete') {
      setStartDate({ date: null, name: null }); // Clear the selected time when the "Delete" key is pressed
    }
  };

  const fileUploadHandler = event => {
    event.preventDefault();

    const file = event.target.files[0];

    if (file !== undefined) {
      setSelectedFile(file);

      const updateFormData = {
        ...formData,
      };
      const updateFormDataElement = {
        ...updateFormData[event.target.name],
      };

      updateFormDataElement.valid = checkValidity(
        updateFormDataElement.value,
        updateFormData[event.target.name].validation,
        event.target,
      );

      updateFormDataElement.touched = true;
      updateFormData[event.target.name] = updateFormDataElement;

      let formIsValid = true;
      for (let key in updateFormData) {
        formIsValid = updateFormData[key].valid && formIsValid;
      }

      setControl(true);
      setFormIsValid(formIsValid);
      setFormData(updateFormData);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type !== 'application/pdf') {
          setImgPreviewUrl(reader.result);
        } else {
          //setImgPreviewUrl(reader.result);
          //immagine di placeholder
        }
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } else {
      setImgPreviewUrl(null);
    }
  };

  const deleteFileHandler = () => {
    if (resetForm) {
      setFormIsValid(false);
    }
    setImgPreviewUrl(null);
  };

  useEffect(() => {
    if (formData?.district?.value !== 'San Marino') {
      getDistricts('it').then(data => {
        setDistricts(data);
      });
    }
  }, [formData?.district?.value]);

  useEffect(() => {
    getCountries().then(data => {
      setCountries(data);
    });

    if (formData?.country?.value) {
      setDistricts([]);
      getDistricts(formData?.country?.value)
        .then(data => {
          setDistricts(data);
        })
        .then(() => {
          setCities([]);
          getCities(formData?.district?.value).then(data => {
            setCities(data);
          });
        });
    }
  }, []);

  useEffect(() => {
    if (cities.length === 0) {
      setZipcode('');
    }
  }, [cities]);

  useEffect(() => {
    if (startDate.date !== null) {
      for (var i = 0; i < datepickername.length; i++) {
        if (startDate.name === datepickername[i]) {
          const updateFormData = {
            ...formData,
          };
          const updateFormDataElement = {
            ...updateFormData[`${datepickername[i]}`],
          };

          if (startDate) {
            updateFormDataElement.value = startDate.date;

            updateFormDataElement.valuetosend =
              startDate.name === 'time'
                ? moment(startDate.date).format('HH:mm')
                : moment(startDate.date).format('DD/MM/YYYY');
          }

          updateFormDataElement.valid = checkValidity(
            updateFormDataElement.value,
            updateFormDataElement.validation,
            {},
          );
          updateFormDataElement.touched = true;
          updateFormData[`${datepickername[i]}`] = updateFormDataElement;

          let formIsValid = true;

          for (let key in updateFormData) {
            formIsValid = updateFormData[key].valid && formIsValid;
          }

          setControl(true);
          setFormIsValid(formIsValid);
          setFormData(updateFormData);
        }
      }
    }
  }, [startDate]);

  const setFormValid = event => {
    const updateFormData = {
      ...formData,
    };

    const updateFormDataElement = {
      ...updateFormData[event.target.name],
    };

    updateFormDataElement.valid = checkValidity(
      updateFormDataElement.value,
      updateFormData[event.target.name].validation,
      event.target,
    );

    updateFormDataElement.touched = true;
    updateFormData[event.target.name] = updateFormDataElement;

    let formIsValid = true;
    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid;
    }

    setControl(true);
    setFormIsValid(formIsValid);
    setFormData(updateFormData);
  };

  return {
    setFormIsValid,
    inputChangedHandler,
    formSubmitHandler,
    formData,
    firstSubmit,
    showError,
    startDate: startDate.date,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    formIsValid,
    resetFormHandler,
    countries,
    districts,
    cities,
    zipcode,
    setFormValid,
    resetInputHandler,
    formIsValid,
    handleKeyDown,
    setFormData,
    selectChange,
    variantLabel,
    variantValue,
  };
};

export default useForm;
